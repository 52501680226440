<script>
    export default {
        name: "ProductPrice",
        props: {
            oldPrice: Number,
            price: Number,
            discountCount: Number,
            isSmallSize: Boolean,
            isMediumSize: Boolean,
            isCardSize: Boolean,
        },
        computed: {
            isDiscount() {
                return this.discountCount;
            },
        },
    };
</script>

<template>
    <div
        class="product-price"
        :class="{
            'product-price--sm': isSmallSize,
            'product-price--md': isMediumSize,
            'product-price--card': isCardSize,
        }"
    >
        <div class="product-price__wrap">
            <div
                class="product-price__number product-price__number--current"
                :class="{
                    'product-price__number--discount': isDiscount,
                }"
            >
                {{ price | formatPrice }}₽
            </div>
            <div v-if="isDiscount" class="product-price__number product-price__number--old">
                {{ oldPrice | formatPrice }}₽
            </div>
        </div>
        <div v-if="isDiscount" class="product-price__label">-{{ discountCount }}%</div>
    </div>
</template>

<style lang="scss">
    .product-price {
        position: relative;
        display: flex;
        align-items: center;
    }

    .product-price__label {
        width: 48px;
        height: 22px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        background: var(--danger);
        color: #fff;
        border-radius: 50px;
        margin-left: 14px;
        text-align: center;

        @include bp($bp-desktop-sm) {
            width: 52px;
            height: 24px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .product-price__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .product-price__number {
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: var(--secondary-text);

        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 36px;
        }
    }

    //
    // --mod
    //

    .product-price__number--current {
        margin-right: 8px;
    }

    .product-price__number--discount {
        color: var(--danger-text);
    }

    .product-price__number--old {
        font-weight: 500;
        font-size: 14px;
        color: var(--secondary-text);
        text-decoration: line-through;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
        }
    }

    .product-price--sm {
        .product-price__number {
            font-size: 12px;
            line-height: 18px;
        }

        .product-price__number--old {
            font-size: 10px;
        }

        .product-price__label {
            font-size: 10px;
            width: 38px;
            height: 18px;
            line-height: 18px;
            margin-left: 10px;
        }
    }

    .product-price--md {
        .product-price__number {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            @include bp($bp-desktop-sm) {
                font-size: 16px;
                line-height: 27px;
            }
        }

        .product-price__number--old {
            margin-bottom: 3px;

            @include bp($bp-desktop-sm) {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
            }
        }

        .product-price__label {
            width: 36px;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .product-price--card {
        .product-price__number {
            font-size: 14px;
            line-height: 20px;

            @include bp($bp-desktop-sm) {
                font-size: 22px;
                line-height: 28px;
            }
        }

        .product-price__number--old {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 2px;

            @include bp($bp-desktop-sm) {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .product-price__label {
            width: 36px;
            font-size: 12px;
            line-height: 18px;
            height: 18px;

            @include bp($bp-desktop-sm) {
                width: 52px;
                font-size: 16px;
                line-height: 24px;
                height: 24px;
            }
        }
    }
</style>
