var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card",class:( _obj = {
        'product-card--loading': _vm.loading,
        'product-card--sm': _vm.isSmall
    }, _obj[_vm.product.slug] = _vm.product.slug, _obj )},[_c('div',{staticClass:"product-card__header"},[_c('div',{staticClass:"product-card__img"},[_c('router-link',{staticClass:"product-card__img-link js-lazy-load",attrs:{"to":{
                    name: 'Product',
                    params: { slug: _vm.product.slug },
                },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('img',{staticClass:"product-card__img-product",attrs:{"data-src":_vm.imageUrl}}),_c('div',{staticClass:"product-card__preloader"},[_c('preloader',{attrs:{"show":true,"lg-size":""}})],1),(_vm.product.slug === 'miku-grill')?_c('div',{staticClass:"product-card__img-product--grill"},[_c('img',{staticClass:"image-grill-1",attrs:{"data-src":"/static/mi-ku/products/miku-grill--level-1.png"}})]):_vm._e(),(_vm.product.slug === 'miku-container')?_c('div',{staticClass:"product-card__img-product--container"},[_c('img',{staticClass:"image-container-1",attrs:{"data-src":"/static/mi-ku/products/miku-container--level-1.png"}}),_c('img',{staticClass:"image-container-2",attrs:{"data-src":"/static/mi-ku/products/miku-container--level-2.png"}}),_c('img',{staticClass:"image-container-3",attrs:{"data-src":"/static/mi-ku/products/miku-container--level-3.png"}}),_c('img',{staticClass:"image-container-4",attrs:{"data-src":"/static/mi-ku/products/miku-container--level-4.png"}})]):_vm._e()]),(_vm.foodIcons)?_c('div',{staticClass:"product-card__icons"},[_c('product-card-icons',{attrs:{"icons":_vm.foodIcons,"is-small":_vm.isSmall}})],1):_vm._e()],1)]),_c('div',{staticClass:"product-card__footer"},[_c('router-link',{staticClass:"product-card__wrap",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug },
            }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__name"},[_vm._v(" "+_vm._s(_vm.nameProduct)+" ")]),_c('div',{staticClass:"product-card__price"},[_c('product-price',{attrs:{"price":_vm.discountPrice,"old-price":_vm.price,"discount-count":_vm.discountPercent,"is-card-size":""}})],1)]),_c('product-card-buttons',{attrs:{"offer-id":_vm.offerId,"product":_vm.product,"is-pre-order":_vm.isPreOrder,"in-cart":_vm.inCart,"discount-price":_vm.discountPrice,"size-button":_vm.sizeButton},on:{"on-added-to-cart":function($event){_vm.inCart = true}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }