<script>
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductCardButtons from "@mk/components/ProductCardButtons";
    import ProductPrice from "@mk/components/ProductPrice";
    import ProductCardIcons from "@mk/components/ProductCardIcons";

    export default {
        name: "ProductCard",
        components: {
            ProductCardIcons,
            ProductPrice,
            ProductCardButtons,
        },
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            return {
                currentOffers,
                offer: currentOffers[0],
                offerId: currentOffers[0].id,
                previewImage: this.product.previewImage?.medium,
                price: currentOffers[0].price,
                discountPrice: currentOffers[0].discountPrice,
                discountPercent: currentOffers[0].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
            };
        },
        computed: {
            nameProduct() {
                return this.product.shortName ? this.product.shortName : this.product.name;
            },
            sizeButton() {
                return this.isSmall ? "sm" : "md";
            },
            foodIcons() {
                const icons = {
                    "miku-grill": ["steak", "meatballs", "shrimp", "toast", "chicken"],
                    "miku-container": ["egg", "meatballs", "soup", "broccoli"],
                };

                return icons[this.product.slug];
            },
            imageUrl() {
                const images = {
                    "miku-grill": "miku-grill",
                    "miku-container": "miku-container",
                };

                return images[this.product.slug]
                    ? `/static/mi-ku/products/${images[this.product.slug]}.png`
                    : apiUrl(this.previewImage);
            },
        },
        methods: {
            apiUrl,
            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <div
        class="product-card"
        :class="{
            'product-card--loading': loading,
            'product-card--sm': isSmall,
            [product.slug]: product.slug,
        }"
    >
        <div class="product-card__header">
            <div class="product-card__img">
                <router-link
                    @click.native.prevent="sendDataLayerEvent"
                    :to="{
                        name: 'Product',
                        params: { slug: product.slug },
                    }"
                    class="product-card__img-link js-lazy-load"
                    tabindex="-1"
                >
                    <img class="product-card__img-product" :data-src="imageUrl" />

                    <div class="product-card__preloader">
                        <preloader :show="true" lg-size></preloader>
                    </div>

                    <div
                        class="product-card__img-product--grill"
                        v-if="product.slug === 'miku-grill'"
                    >
                        <img
                            class="image-grill-1"
                            data-src="/static/mi-ku/products/miku-grill--level-1.png"
                        />
                    </div>

                    <!-- remove later-->
                    <div
                        class="product-card__img-product--container"
                        v-if="product.slug === 'miku-container'"
                    >
                        <img
                            class="image-container-1"
                            data-src="/static/mi-ku/products/miku-container--level-1.png"
                        />
                        <img
                            class="image-container-2"
                            data-src="/static/mi-ku/products/miku-container--level-2.png"
                        />
                        <img
                            class="image-container-3"
                            data-src="/static/mi-ku/products/miku-container--level-3.png"
                        />
                        <img
                            class="image-container-4"
                            data-src="/static/mi-ku/products/miku-container--level-4.png"
                        />
                    </div>
                    <!-- remove later-->
                </router-link>
                <div v-if="foodIcons" class="product-card__icons">
                    <product-card-icons
                        :icons="foodIcons"
                        :is-small="isSmall"
                    ></product-card-icons>
                </div>
            </div>
        </div>

        <div class="product-card__footer">
            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug },
                }"
                class="product-card__wrap"
            >
                <div class="product-card__name">
                    {{ nameProduct }}
                </div>

                <div class="product-card__price">
                    <product-price
                        :price="discountPrice"
                        :old-price="price"
                        :discount-count="discountPercent"
                        is-card-size
                    ></product-price>
                </div>
            </router-link>
            <product-card-buttons
                :offer-id="offerId"
                :product="product"
                :is-pre-order="isPreOrder"
                :in-cart="inCart"
                :discount-price="discountPrice"
                @on-added-to-cart="inCart = true"
                :size-button="sizeButton"
            ></product-card-buttons>
        </div>
    </div>
</template>

<style lang="scss">
    .product-card {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px #f2f2f5;
        transition: 0.3s ease-in-out all;
        &.swiper-slide {
            height: auto;
        }

        //remove later --start
        &.miku-grill {
            .image-grill-1 {
                z-index: 3;
                opacity: 0;
                transition: opacity 0.2s ease-in, transform 0.2s ease-in;
                transform: scale(0.97);
            }
        }

        &.miku-container {
            .product-card__img-product {
                display: none;
            }
            .image-container-1 {
                transform: translateY(20%) scale(1.2);
                z-index: 4;
            }
            .image-container-2 {
                transform: translateY(-5%) scale(1.2);
                z-index: 3;
            }
            .image-container-3 {
                transform: translateY(-30%) scale(1.2);
                z-index: 2;
            }
            .image-container-4 {
                transform: translateY(-30%) scale(1.2);
                z-index: 4;
            }
        }
        //remove later --end

        @include bp($bp-desktop-sm) {
            border-radius: 25px;
            box-shadow: 0 0 25px #f2f2f5;

            //remove later --start
            &.miku-grill {
                &:hover {
                    .image-grill-1 {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
            &.miku-container {
                &:hover {
                    .product-card__img-link {
                        img {
                            transform: scale(0.75);
                        }
                    }
                }
            }
            //remove later --end

            &:hover {
                box-shadow: 0 0 0 2px #e0e6e9;

                .product-card__img-link {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    .product-card__header {
        position: relative;
        padding: 4px 4px 8px;

        @include bp($bp-desktop-sm) {
            padding: 16px 16px 10px;
        }
    }

    .product-card__icons {
        position: absolute;
        z-index: 10;
        left: 8px;
        bottom: 8px;
    }

    .product-card__img {
        position: relative;
        background-color: #f8f8fa;
        border-radius: 8px;
        transition: 0.3s ease-in-out background-color;

        @include bp($bp-desktop-sm) {
            border-radius: 15px;
        }
    }

    .product-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;

        img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
            transform: scale(0.97);
            transition: 0.3s ease-in-out transform, 0.3s ease-in-out filter;
        }
        @include bp($bp-desktop-sm) {
            padding-bottom: 52%;
        }
    }

    .product-card__preloader {
        opacity: 1;
    }

    .product-card__img-product {
        z-index: 1;
        &[lazy="loaded"] {
            & + .product-card__preloader {
                opacity: 0;
            }
        }
    }

    .product-card__footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 8px 8px;
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 16px 16px;
        }
    }

    .product-card__wrap {
        flex-grow: 1;
        display: block;
        padding: 0 4px;
        @include bp($bp-desktop-sm) {
            padding: 0 8px;
        }
    }

    .product-card__name {
        position: relative;
        z-index: 2;
        display: block;
        margin-bottom: 6px;
        color: var(--primary--dark);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.02em;
        transition: 0.3s ease-in-out all;
        @include bp($bp-desktop-sm) {
            margin-bottom: 5px;
            padding-right: 10px;
            font-weight: 700;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0;
        }
    }

    .product-card__price {
        display: block;
        margin-bottom: 8px;
    }

    //
    // --mod
    //

    .product-card--loading {
        .product-card__btn {
            opacity: 1;
        }
    }

    .product-card--sm {
        box-shadow: 0 0 10px #f2f2f5;
        border-radius: 20px;

        .product-card__header {
            padding: 4px;
            @include bp($bp-desktop-sm) {
                padding: 8px;
            }
        }

        .product-card__name {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            @include bp($bp-desktop-sm) {
                font-size: 14px;
                font-weight: 700;
            }
        }

        .product-card__img-link {
            padding-bottom: 100%;
        }

        .product-card__wrap {
            padding: 0;
        }

        .product-card__controls {
            max-width: 100%;
            padding: 0;
        }

        .product-price__number {
            font-size: 14px;
            line-height: 20px;
            @include bp($bp-desktop-sm) {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .product-card__footer {
            flex-direction: column;
        }
    }
</style>
