<script>
    import TooltipBox from "@mk/components/TooltipBox";
    export default {
        name: "ProductCardIcons",
        components: { TooltipBox },
        props: {
            icons: Array,
            isSmall: Boolean,
        },
        computed: {
            iconsTooltip() {
                return {
                    steak: "Стейк",
                    meatballs: "Котлеты",
                    shrimp: "Креветки",
                    toast: "Сэндвичи",
                    chicken: "Крылышки",
                    egg: "Яичница",
                    soup: "Суп",
                    broccoli: "Брокколи",
                };
            },
        },
    };
</script>

<template>
    <div class="product-card-icons" :class="{ 'product-card-icons--sm': isSmall }">
        <div class="product-card-icons__image" v-for="(icon, index) in icons" :key="index">
            <img :src="`/static/mi-ku/icons/product/${icon}.svg`" alt="" />
            <tooltip-box class="product-card-icons__tooltip">
                {{ iconsTooltip[icon] }}
            </tooltip-box>
        </div>
    </div>
</template>

<style lang="scss">
    .product-card-icons {
        display: flex;
        margin: 0 -1px;
        @include bp($bp-desktop-sm) {
            margin: 0 -4px;
        }
    }
    .product-card-icons--sm {
        .product-card-icons__image {
            width: 16px;
            height: 16px;
            margin: 0 1px;
        }
    }

    .product-card-icons__image {
        width: 16px;
        height: 16px;
        margin: 0 1px;
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 3;
            width: 24px;
            height: 24px;
            margin: 0 4px;
            &:hover {
                .product-card-icons__tooltip {
                    display: block;
                }
            }
        }
    }
</style>
