<script>
    export default {
        name: "TooltipBox",
        props: {
            isError: Boolean,
            positionX: {
                type: String,
                validator: (v) => ["left", "right"].includes(v),
                default: "left",
            },
            positionY: {
                type: String,
                validator: (v) => ["top", "bottom"].includes(v),
                default: "top",
            },
        },
        computed: {
            computeClassNames() {
                return [
                    `tooltip-box--${this.positionX}`,
                    `tooltip-box--${this.positionY}`,
                    `${this.isError ? "tooltip-box--error" : ""}`,
                ].filter(Boolean);
            },
        },
    };
</script>

<template>
    <div class="tooltip-box" :class="computeClassNames">
        <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.5 9L0.0048101 0.749999L12.9952 0.75L6.5 9Z" fill="white" />
        </svg>
        <slot></slot>
    </div>
</template>

<style lang="scss">
    .tooltip-box {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            z-index: 10;
            padding: 7px 15px;
            color: #40404b;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 0 8px 15px #c7c9d9;
            svg {
                position: absolute;
            }
        }
    }

    .tooltip-box--error {
        color: #e91e63;
    }

    .tooltip-box--top {
        bottom: calc(100% + 12px);
        svg {
            bottom: -7px;
        }
    }

    .tooltip-box--bottom {
        top: calc(100% + 12px);
        svg {
            top: -7px;
            transform: rotate(180deg);
        }
    }

    .tooltip-box--left {
        left: -10px;
        svg {
            left: 15px;
        }
    }

    .tooltip-box--right {
        right: -10px;
        svg {
            right: 15px;
        }
    }
</style>
